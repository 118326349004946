import { render, staticRenderFns } from "./person-table.vue?vue&type=template&id=6bef9fe6&scoped=true"
import script from "./person-table.vue?vue&type=script&lang=js"
export * from "./person-table.vue?vue&type=script&lang=js"
import style0 from "./person-table.vue?vue&type=style&index=0&id=6bef9fe6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bef9fe6",
  null
  
)

export default component.exports
<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @search="search" @add="clickAdd"></search-form>
    <!-- 人员列表 -->
    <person-table :list="list" @edit="clickEdit" @delete="handleDel"></person-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="currentPage"
    ></el-pagination>
    <!-- 添加、编辑 -->
    <dialog-form
      :is-show="showDialog"
      :is-edit="isEdit"
      :edit-data="editData"
      @cancel="showDialog = false"
      @submit-dialog="submitDialog"
    ></dialog-form>
  </div>
</template>

<script>
import searchForm from './components/search-form';
import personTable from './components/person-table';
import dialogForm from './components/dialog';

import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
      showDialog: false,
      isEdit: false,
      editData: {},
      searchData: {}
    };
  },
  components: {
    searchForm,
    personTable,
    dialogForm
  },
  methods: {
    init() {
      let initParams = { pageNum: 1 };
      this.getList(initParams);
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 搜索
    search(data) {
      let params = Object.assign({ pageNum: 1 }, data);
      this.searchData = data;
      return this.getList(params);
    },
    // 添加
    clickAdd() {
      this.showDialog = true;
      this.isEdit = false;
    },
    // 点击编辑
    clickEdit(row) {
      let { isUpdate = true } = row;
      if (!isUpdate) {
        this.$notify({
          message: `用户【${row.nickname}】已被禁用，暂不可编辑！`,
          type: 'error',
          offset: 400,
          duration: 1500
        });
        return;
      }
      this.showDialog = true;
      this.editData = row;
      this.isEdit = true;
    },
    // 点击确定
    submitDialog(form) {
      if (this.isEdit) {
        return this.editPerson(form);
      } else {
        return this.addPerson(form);
      }
    },
    // 提交编辑
    editPerson(form) {
      let params = Object.assign(form, { id: this.editData.id });
      return service.edit(params).then(res => {
        this.$message({ type: 'success', message: '编辑成功' });
        this.showDialog = false;
        this.init();
      });
    },
    // 提交添加
    addPerson(form) {
      return service.add(form).then(res => {
        this.$message({ type: 'success', message: '添加成功' });
        this.showDialog = false;
        this.init();
      });
    },
    // 删除
    handleDel(row) {
      this.$alert('确定要将此推广专员永久删除吗？', '提示', { showCancelButton: true }).then(() => {
        return service.del({ id: row.id }).then(res => {
          this.$message({ type: 'success', message: '删除成功' });
          this.init();
        });
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
</style>

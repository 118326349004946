<template>
  <div class="table-wrap">
    <el-table
      border
      :data="copyList"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
    >
      <el-table-column prop="tempId" label="ID" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="nickname" label="管理员" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="一级分成" align="center">
        <template slot-scope="scope">{{scope.row.oneLevelDivision}}%</template>
      </el-table-column>
      <el-table-column label="二级分成" align="center" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.twoLevelDivision}}%</template>
      </el-table-column>
      <el-table-column prop="link" align="center" label="链接" min-width="110">
        <template slot-scope="scope">
          <el-tooltip
            effect="dark"
            content="复制成功"
            placement="top"
            :manual="true"
            :hide-after="10"
            :value="scope.row.showToolTip"
          >
            <span
              class="clip"
              :class="{active: scope.row.showToolTip}"
              @click="handleCopy(scope.$index, scope.row)"
            >{{ scope.row.link }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="code" align="center" label="邀请码" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createdTime" align="center" label="注册时间" min-width="155" sortable></el-table-column>
      <el-table-column align="center" label="认证状态" class-name="table-verify">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            :type="scope.row.stop_status ? 'danger' : 'success'"
          >{{ scope.row.stop_status ? '禁用' : '正常' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- 编辑 -->
          <el-button
            type="success"
            size="mini"
            icon="el-icon-edit"
            circle
            @click.native.prevent="$emit('edit', scope.row)"
          ></el-button>
          <!-- 删除 -->
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            circle
            @click="$emit('delete', scope.row)"
          ></el-button>
          <!-- 查看二维码 -->
          <el-button
            type="info"
            size="mini"
            icon="el-icon-menu"
            circle
            @click="showQrCode(scope.$index, scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    copyList() {
      let copy = JSON.parse(JSON.stringify(this.list));
      copy.forEach(item => (item.showToolTip = false));
      return copy;
    }
  },
  methods: {
    // 显示二维码
    showQrCode(index, row) {
      this.$alert(`<img src=${row.qr_code}></img>`, '二维码', {
        showConfirmButton: false,
        closeOnClickModal: false,
        dangerouslyUseHTMLString: true,
        center: true
      });
    },
    handleCopy(index, row) {
      this.$copyText(row.link).then(
        err => {
          row.showToolTip = !row.showToolTip;
          this.copyList.splice(index, 1, row);
        },
        err => this.$message({ message: '复制失败', type: 'error' })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.table-wrap {
  .clip {
    cursor: pointer;
    &.active {
      background-color: rgba(61, 130, 244, 1);
      color: #fff;
    }
  }
}
</style>

<template>
  <div class="dialog">
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      width="40%"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        class="form"
        hide-required-asterisk
      >
        <el-form-item label="管理员" prop="adminId">
          <el-select v-model="form.adminId" placeholder="选择管理员">
            <el-option
              v-for="item in managerOpts"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="一级分成" prop="oneLevelDivision">
          <el-input-number
            v-model="form.oneLevelDivision"
            :min="0"
            :max="100"
            placeholder="请输入一级分成比例"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="二级分成" prop="twoLevelDivision">
          <el-input-number
            v-model="form.twoLevelDivision"
            :min="0"
            :max="100"
            placeholder="请输入二级分成比例"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="认证状态" prop="stopStatus">
          <el-switch
            v-model="form.stopStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="正常"
            inactive-text="禁用"
            :active-value="0"
            :inactive-value="1"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from '../api';
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: { adminId: '', oneLevelDivision: 0, twoLevelDivision: 0, stopStatus: 0 },
      managerOpts: [],
      rules: {
        adminId: [{ required: true, message: '请填写选择管理员', trigger: 'change' }]
      }
    };
  },
  computed: {
    title() {
      return this.isEdit ? '编辑' : '添加专员';
    }
  },
  methods: {
    // 获取管理员选项
    getManager(params) {
      return service.getManager(params).then(res => {
        let { list = [] } = res;
        let opts = [];
        list.forEach(item => {
          opts.push({ id: item.id || '', label: item.nickname || '' });
        });
        this.managerOpts = opts;
      });
    },
    cancel() {
      this.$refs['form'].resetFields();
      this.$emit('cancel');
    },
    submit() {
      // 校验
      let check = true;
      this.$refs['form'].validate(res => (check = res));
      if (!check) return;

      this.$emit('submit-dialog', this.form);
      // 提交后表单清除
      this.$refs['form'].resetFields();
    },
    // 编辑状态填充数据
    openDialog() {
      if (this.isEdit) {
        this.$nextTick(() => {
          let { form, editData, managerOpts } = this;
          managerOpts.forEach(item => {
            if (item.label === editData.nickname) form.adminId = item.id;
          });
          form.oneLevelDivision = editData.oneLevelDivision;
          form.twoLevelDivision = editData.twoLevelDivision;
          form.stopStatus = editData.stop_status;
        });
      }
    }
  },
  mounted() {
    this.getManager({ pageNum: 1, pageSize: 50 });
  }
};
</script>

<style lang="scss" scoped>
.dialog {
  .form {
    width: 80%;
    margin: 0 auto;
    text-align: left;
  }
}
</style>
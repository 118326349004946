<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.name" class="account">
          <template slot="prepend">名称</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="search.stopStatus" placeholder="状态">
          <el-option
            v-for="item in statusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="add-btn" @click="$emit('add')">添加</el-button>
      </el-form-item>
      <el-form-item>
        <el-button class="search-btn" @click="searchList">查找</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        name: '',
        stopStatus: null
      },
      // 状态选项
      statusOpts: [
        { value: '', label: '请选择' },
        { value: 0, label: '正常' },
        { value: 1, label: '禁用' }
      ]
    };
  },
  methods: {
    searchList() {
      this.$emit('search', this.search);
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .account.el-input {
    width: 160px;
  }
  .mobile.el-input {
    width: 220px;
  }
  .el-select {
    width: 120px;
  }
  .add-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
  .search-btn {
    background-color: #5766da;
    border: 1px solid #5766da;
    color: #fff;
  }
}
</style>
import API from "@/api";

export default {
  getList: params => {
    return API.requestGet({
      params,
      url: "sponsored/getList"
    });
  },
  // 获取管理员列表
  getManager: params => {
    return API.requestGet({
      url: "admin/getList",
      params
    });
  },
  add: params => {
    return API.requestPost({
      url: "sponsored/add",
      data: params
    });
  },
  edit: params => {
    return API.requestPost({
      url: "sponsored/edi",
      data: params
    });
  },
  del: params => {
    return API.requestPost({
      url: "sponsored/del",
      data: params
    });
  }
};
